export const OB = {
  id: '333643',
  name: 'OB',
  variants: ['30616'],
  variantOpenBanking: '30616',
};

export const PPT = {
  id: '343023',
  name: 'PPT enable',
  variants: ['44284'],
  variantPPTDisabled: '44284',
};

export const FEE_CARD_LANDING = {
  id: '343131',
  name: 'Fee Card Multiproduct',
  variants: ['44470', '44471', '44483'],
  control: '44470',
  treatmentA: '44471',
  treatmentB: '44483',
};
